import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Pagination, Row, Col } from 'react-bootstrap'
class Pages extends Component {

    pageItems = () => {
        const { page, countPages } = this.props
        let items = []
        const ellipsisLeft = page - 2 > 1
        const ellipsisRight = page + 2 < countPages
        if (ellipsisLeft) items = [...items, <Pagination.Ellipsis key="ellipsisLeft" />]

        for (let i = (ellipsisLeft ? page - 2 : 1); i <= (ellipsisRight ? page + 2 : countPages); i++) {
            items = [
                ...items,
                <Pagination.Item
                    key={i}
                    onClick={() => this.props.handlePageChange(i)}
                    active={page === i}>
                    {i}
                </Pagination.Item>
            ]
        }

        if (ellipsisRight) items = [...items, <Pagination.Ellipsis key="ellipsisRight" />]
        return items
    }

    render() {
        const { countPages, page } = this.props
        if (countPages === 0) {
            return null
        }
        return (
            <Row style={{ float: "right" }}>
                {/* <Col style={{ margin: "auto" }}>Page {page}/{countPages}</Col> */}
                <Col >
                    <Pagination style={{ margin: "auto" }}>
                        <Pagination.First onClick={() => this.props.handlePageChange(1)} />
                        {this.pageItems()}
                        <Pagination.Last onClick={() => this.props.handlePageChange(countPages)} />
                    </Pagination>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Pages)
