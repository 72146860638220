import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Button } from "react-bootstrap";
import { updateAccount, sendResetPassword, logoutUserFromAllDevices, resetPin } from "../../../redux/actions/business/account";
import Loading from "../../../layouts/Loading/Loading";
import {
    formatPhoneNumber,
    //validatePhoneNumber
} from "../../../utils/common/FormatPhoneNumber/FormatPhoneNumber";
import UnescapeString from "../../../utils/common/UnexcapeString/unexcapeString";
import {
    //updateBusinessAccount, 
    addAccount
} from "../../../redux/actions/business/business-account";

class BusinessAccountForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            account: {
                name: "",
                email: "",
                phone: "",
                active: "true",
                role: "salon_owner",
                notRequiredAdminPasscode: []
            },
            disabledButton: true
        };
    }

    componentDidMount() {
        if (this.props.account) {
            this.setState({
                account: { ...this.props.account }
            });
        }

    }

    handleChange = e => {
        const field = e.target.name;
        let value = e.target.value;

        let account = this.state.account;
        if (field === "phone") {
            value = formatPhoneNumber(value, "CA");
        }
        account[field] = value;
        this.setState({
            account,
            disabledButton: false
        });
    };

    // updateBusinessAccount = (business) => {
    //     const confirm = window.confirm("Are your sure to use this account for the business?")
    //     if (confirm) {
    //         this.props.updateBusinessAccount(business)
    //     }
    // }

    handleSubmit = e => {
        e.preventDefault();
        const { business } = this.props
        let { account } = this.state;

        // if (validatePhoneNumber(account.phone, "CA")) {
        if (account._id) {

            this.props.updateAccount(account);
        } else {
            account.business = business
            account.businesses = [business]
            this.props.addAccount(account);
            //close the modal after created an account
            this.props.closeForm()
        }

        this.setState({
            disabledButton: true
        });
        //}
    };

    handleResetPassword = (email) => () => {
        const confirmed = window.confirm("A reset email will be sent to the user email. Confirm?")
        if (confirmed) {
            this.props.sendResetPassword(email)
        }

    }

    handleLogoutAllDevices = (email) => () => {
        const confirmed = window.confirm("User will be logout from all devices. Confirm?")
        if (confirmed) {
            this.props.logoutUserFromAllDevices(email)
        }
    }

    handleResetAdminPasscode = () => {
        const confirmed = window.confirm("Are you sure to reset this admin passcode to default (0000). Confirm?")
        if (confirmed) {
            this.props.resetPin(this.state.account._id)
        }
    }

    handleChangeNotRequirePasscodeFields = (e) => {
        let { account } = this.state
        const value = e.target.value
        let notRequiredAdminPasscode = account.notRequiredAdminPasscode || []
        if (!notRequiredAdminPasscode.includes(value)) {
            notRequiredAdminPasscode.push(value);
        } else {
            notRequiredAdminPasscode = notRequiredAdminPasscode.filter(item => item !== value);
        }

        if (notRequiredAdminPasscode.includes("")) {
            notRequiredAdminPasscode = []
        }

        account.notRequiredAdminPasscode = notRequiredAdminPasscode
        this.setState({ account, disabledButton: false })
    }


    render() {
        const { account, disabledButton, } = this.state;
        const { loading } = this.props;

        return (
            <Form onSubmit={this.handleSubmit} method='POST'>
                <Form.Group>
                    <Row>
                        <Col md={6}>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Name'
                                name='name'
                                value={UnescapeString(account.name)}
                                onChange={this.handleChange}
                                required
                            />
                        </Col>
                        <Col md={6}>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type='email'
                                placeholder='name@example.com'
                                name='email'
                                onChange={this.handleChange}
                                required
                                value={account.email}
                            />
                        </Col>
                        <Col md={6}>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Phone'
                                name='phone'
                                onChange={this.handleChange}
                                required
                                value={account.phone}
                            />
                        </Col>
                        <Col md={6}>
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                                as='select'
                                name='active'
                                onChange={this.handleChange}
                                required
                                value={account.active}
                            >
                                <option value='' disabled>
                                    Select Status
                                </option>
                                <option value='true'>Active</option>
                                <option value='false'>Inactive</option>
                            </Form.Control>
                        </Col>
                        <Col md={6}>
                            <Form.Label>Role</Form.Label>
                            <Form.Control
                                as='select'
                                name='role'
                                onChange={this.handleChange}
                                value={account.role}
                                required
                            >
                                <option value='' disabled>
                                    Select Role
                                </option>
                                <option value='salon_owner'>Owner</option>
                                <option value='salon_employee'>Employee</option>
                            </Form.Control>
                        </Col>
                        <Col md={6}>
                            <Form.Label>Not Require Passcode Fields</Form.Label>
                            <Form.Control
                                as='select'
                                name='notRequiredAminPasscode'
                                onChange={e => this.handleChangeNotRequirePasscodeFields(e)}
                                // onChange={e => this.setState({ account: { ...this.state.account, notRequiredAdminPasscode: [].slice.call(e.target.selectedOptions).map(item => item.value) } })}
                                value={account.notRequiredAdminPasscode}
                                multiple
                            >
                                <option value=''>None</option>
                                <option value='clients'>Clients</option>
                                <option value='menuSettings'>Menu Settings</option>
                                <option value='staffSettings'>Staff Settings</option>
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            {!loading ? (
                                <Button
                                    variant='primary'
                                    className='float-left'
                                    type='submit'
                                    disabled={disabledButton}
                                >
                                    Save
                                </Button>
                            ) : (
                                <Loading />
                            )}
                        </Col>

                        {account._id && (
                            <Col>
                                <Button variant='link' onClick={this.handleResetAdminPasscode}>Reset Admin Passcode</Button>
                                <Button variant='link' onClick={this.handleResetPassword(account.email)}>Reset Password</Button>
                                <Button variant='link' onClick={this.handleLogoutAllDevices(account.email)}>
                                    Logout all devices
                                </Button>
                            </Col>
                        )}




                    </Row>
                </Form.Group>
            </Form>
        );
    }
};

const mapStateToProps = state => ({
    loading: state.account.loading,
})

const mapDispatchToProps = dispatch => {
    return {
        addAccount: account => dispatch(addAccount(account)),
        updateAccount: account => dispatch(updateAccount(account)),
        sendResetPassword: email => dispatch(sendResetPassword(email)),
        logoutUserFromAllDevices: email => dispatch(logoutUserFromAllDevices(email)),
        //updateBusinessAccount: business => dispatch(updateBusinessAccount(business)),
        resetPin: accountId => dispatch(resetPin(accountId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessAccountForm);
