import React, { useState } from "react";
import { Form, FormGroup, Button, Row, Col, InputGroup } from "react-bootstrap";
import { countryCodes } from "../../../utils/utility";
import {
    // displayNationalNumber,
    formatPhoneNumber,
    getNumberInternational,
    validatePhoneNumber,
} from "../../../utils/common/FormatPhoneNumber/FormatPhoneNumber";
import API from "../../../utils/API/API";
import Loading from "../../../layouts/Loading/Loading";

const { Label } = Form;

const AppointmentSettingForm = (props) => {
    var business = props.business;

    var currentSettings = props.business && props.business.appointmentSettings;
    if (!currentSettings) {
        currentSettings = {
            isGoogleBookingEnable: false,
            isAutoConfirmed: true,
            afterHoursDuration: 0,
            intervalBlockTime: 15,
            notificationEmails: [],
            minimumRequiredForBooking: 0,
            maximumAllowedForBooking: 0,
            notificationPhones: [],
            toFutureDays: 60,
            // receiveNotificationsByPhone: false,
            hoursRequiredPriorAppointment: 0,
            disableReviewComment: false,

            hideReview: false,
            lastAppointmentTime: null,
            isPriceHidden: false,
            displaySocialMediaFromStar: 5,
            isDisableGroupBooking: false,
        };
    }

    const [isGoogleBookingEnable, setAllowGoolgeBooking] = useState(
        currentSettings.isGoogleBookingEnable
    );
    const [isAutoConfirmed, setIsAutoConfirmed] = useState(
        currentSettings.isAutoConfirmed
    );
    const [toFutureDays, setToFutureDays] = useState(
        currentSettings.toFutureDays
    )
    const [afterHoursDuration, setAfterHoursDuration] = useState(
        currentSettings.afterHoursDuration
    );
    const [notificationEmails, setNotificationEmails] = useState(
        currentSettings.notificationEmails
    );
    const [minimumRequiredForBooking, setMinimumRequiredForBooking] = useState(
        currentSettings.minimumRequiredForBooking
    );
    const [maximumAllowedForBooking, setMaximumAllowedForBooking] = useState(
        currentSettings.maximumAllowedForBooking
    );
    const [notificationPhones, setNotificationPhones] = useState(
        currentSettings.notificationPhones
    );
    const [country, setCountry] = useState(business.country);
    const [hoursRequiredPriorAppointment, setHoursRequiredPriorAppointment] =
        useState(currentSettings.hoursRequiredPriorAppointment);
    const [disableReviewComment, setDisableReviewComment] = useState(
        currentSettings.disableReviewComment
    );
    // const [directGoogleReview, setDirectGoogleReview] = useState(currentSettings.directGoogleReview);
    const [lastAppointmentTime, setLastAppointmentTime] = useState(
        currentSettings.lastAppointmentTime
    );
    const [intervalBlockTime, setIntervalBlockTime] = useState(
        currentSettings.intervalBlockTime
    );
    const [isPriceHidden, setIsPriceHidden] = useState(
        currentSettings.isPriceHidden
    );
    const [displaySocialMediaFromStar, setDisplaySocialMediaFromStar] =
        useState(currentSettings.displaySocialMediaFromStar);
    const [hideReview, setHideReview] = useState(currentSettings.hideReview);

    const [isDisableGroupBooking, setIsDisableGroupBooking] = useState(currentSettings.isDisableGroupBooking);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);

    // const handleChangeReceiveNotificationsByPhone = (value) => {
    //     setReceiveNotificationsByPhone(value)
    //     if (!value) {
    //         setNotificationPhones([])
    //     }
    // }

    const handleEmailNotificationChange = (e, index) => {
        const emails = [...notificationEmails];

        if (!emails.includes(e.target.value)) {
            emails[index] = e.target.value;
        } else {
            emails.splice(index, 1);
        }
        setNotificationEmails(emails);
    };

    const handleAddEmailNotifications = () => {
        setNotificationEmails([...notificationEmails, ""]);
    };

    const handleRemoveEmailNotification = (index) => {
        const emails = [...notificationEmails];
        emails.splice(index, 1);
        setNotificationEmails(emails);
    };

    const handleNotificationPhoneChange = (e, index) => {
        const phones = [...notificationPhones];
        const value = formatPhoneNumber(e.target.value, country);
        if (!phones.includes(value)) {
            phones[index] = value;
        } else {
            phones.splice(index, 1);
        }
        setNotificationPhones(phones);
    };

    const handleAddNotificationPhone = () => {
        setNotificationPhones([...notificationPhones, ""]);
    };

    const handleRemoveNotificationPhone = (index) => {
        const phones = [...notificationPhones];
        phones.splice(index, 1);
        setNotificationPhones(phones);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission
        let appointmentSettings = {
            isGoogleBookingEnable,
            isAutoConfirmed,
            afterHoursDuration,
            notificationEmails,
            minimumRequiredForBooking,
            maximumAllowedForBooking,
            notificationPhones,
            hoursRequiredPriorAppointment,
            intervalBlockTime,
            disableReviewComment,
            toFutureDays,
            // directGoogleReview,
            hideReview,
            displaySocialMediaFromStar,
            lastAppointmentTime,
            isPriceHidden,
            isDisableGroupBooking
        };

        if (notificationPhones.filter((phone) => !!phone).length > 0) {
            const phones = notificationPhones
                .map((phoneNum) => {
                    if (validatePhoneNumber(phoneNum, country)) {
                        return getNumberInternational(phoneNum, country);
                    } else {
                        return null;
                    }
                })
                .filter((phone) => !!phone);
            appointmentSettings.notificationPhones = phones;
        }

        try {
            setLoading(true);
            const res = await API.patch(
                "/api/businesses/update-appointment-settings",
                { business: business._id, appointmentSettings }
            );
            if (res.data) {
                setMessage(
                    "Appointment settings has been updated successfully!"
                );
            }
            setLoading(false);
            setError(null);
        } catch (error) {
            setLoading(false);
            setError(error.message);
        }
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col>
                    {error && (
                        <p className='bg-danger text-white p-2'>{error}</p>
                    )}
                    {message && (
                        <p className='bg-success text-white p-2'>{message}</p>
                    )}
                </Col>
            </Row>
            <Row className='mt-1'>
                <Col>
                    <FormGroup>
                        <Label>
                            <Form.Check
                                type='checkbox'
                                checked={isGoogleBookingEnable}
                                onChange={(e) =>
                                    setAllowGoolgeBooking(e.target.checked)
                                }
                            />{" "}
                            Google Booking Enable
                        </Label>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>
                            <Form.Check
                                type='checkbox'
                                checked={isAutoConfirmed}
                                onChange={(e) =>
                                    setIsAutoConfirmed(e.target.checked)
                                }
                            />{" "}
                            Auto Confirmed
                        </Label>
                    </FormGroup>
                </Col>
            </Row>
            <Row className='mt-1'>
                <Col>
                    <FormGroup>
                        <Label>
                            <Form.Check
                                type='checkbox'
                                checked={disableReviewComment}
                                onChange={(e) =>
                                    setDisableReviewComment(e.target.checked)
                                }
                            />{" "}
                            Disable Review Comment
                        </Label>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>
                            <Form.Check
                                type='checkbox'
                                checked={isPriceHidden}
                                onChange={(e) =>
                                    setIsPriceHidden(e.target.checked)
                                }
                            />{" "}
                            Hide the price
                        </Label>
                    </FormGroup>
                </Col>
            </Row>
            <Row className='mt-1'>
                <Col>
                    <FormGroup>
                        <Label>
                            <Form.Check
                                type='checkbox'
                                checked={hideReview}
                                onChange={(e) => {
                                    if (business.isScanReview && !e.target.checked) {
                                        return setError("You can't unhide review when you have scan review")
                                    }
                                    setHideReview(e.target.checked)
                                }
                                }
                            />{" "}
                            Hide Review
                        </Label>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>
                            <Form.Check type="checkbox" checked={isDisableGroupBooking} onChange={(e) => setIsDisableGroupBooking(e.target.checked)} />
                            {' '}
                            Disable Group Booking
                        </Label>
                    </FormGroup>
                </Col>
            </Row>

            <Row className='mt-1'>
                <Col>
                    <Form.Label>
                        Notification Emails{" "}
                        <Button
                            variant='link'
                            onClick={handleAddEmailNotifications}
                        >
                            <span className='oi oi-plus'></span> Email
                        </Button>
                    </Form.Label>
                    {notificationEmails.map((email, index) => (
                        <div
                            className='d-flex align-items-center m-1'
                            key={index}
                        >
                            <Form.Control
                                type='email'
                                placeholder='Enter email address'
                                value={email}
                                onChange={(e) =>
                                    handleEmailNotificationChange(e, index)
                                }
                                className='mr-2'
                            />
                            <Button
                                variant='link'
                                onClick={() =>
                                    handleRemoveEmailNotification(index)
                                }
                            >
                                <span className='oi oi-minus text-danger'></span>
                            </Button>
                        </div>
                    ))}
                </Col>
            </Row>
            <Row className='mt-1'>
                <Col>
                    <Form.Label>
                        Notification Phones{" "}
                        <Button
                            variant='link'
                            onClick={handleAddNotificationPhone}
                        >
                            <span className='oi oi-plus'></span> Phone
                        </Button>
                    </Form.Label>
                    {notificationPhones &&
                        notificationPhones.map((phone, index) => (
                            <div
                                className='d-flex align-items-center m-1'
                                key={index}
                            >
                                <InputGroup>
                                    <Form.Control
                                        as='select'
                                        value={country}
                                        // value={business.country}
                                        onChange={(e) =>
                                            setCountry(e.target.value)
                                        }
                                        name='country'
                                        style={{
                                            maxWidth: "100px",
                                            borderRight: "solid 1px gray",
                                        }}
                                    >
                                        {countryCodes.map((country) => (
                                            <option
                                                value={country.code}
                                                key={country.code}
                                            >
                                                {country.label}
                                            </option>
                                        ))}
                                    </Form.Control>
                                    <Form.Control
                                        type='text'
                                        placeholder='Enter phone number'
                                        value={formatPhoneNumber(
                                            phone,
                                            country
                                        )}
                                        onChange={(e) =>
                                            handleNotificationPhoneChange(
                                                e,
                                                index
                                            )
                                        }
                                        className='mr-2'
                                    />
                                </InputGroup>

                                <Button
                                    variant='link'
                                    onClick={() =>
                                        handleRemoveNotificationPhone(index)
                                    }
                                >
                                    <span className='oi oi-minus text-danger'></span>
                                </Button>
                            </div>
                        ))}
                </Col>
            </Row>
            <Row className='mt-1'>
                <Col>
                    <FormGroup>
                        <Label>Minimum Required for Booking: ($)</Label>
                        <Form.Control
                            type='Number'
                            min={0}
                            max={300}
                            value={minimumRequiredForBooking}
                            onChange={(e) =>
                                setMinimumRequiredForBooking(e.target.value)
                            }
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>Minimum Required for Booking: ($)</Label>
                        <Form.Control
                            type='Number'
                            min={0}
                            max={500}
                            value={maximumAllowedForBooking}
                            onChange={(e) =>
                                setMaximumAllowedForBooking(e.target.value)
                            }
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>After Hours Duration:</Label>
                        <Form.Control
                            as='select'
                            type='text'
                            value={afterHoursDuration}
                            onChange={(e) =>
                                setAfterHoursDuration(e.target.value)
                            }
                        >
                            <option value='0'>0</option>
                            <option value='30'>30min</option>
                            <option value='60'>1h</option>
                            <option value='90'>1h30</option>
                            <option value='120'>2h</option>
                            <option value='150'>2h30</option>
                        </Form.Control>
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Form.Group controlId='exampleForm.ControlSelect1'>
                        <Label>
                            Hours Required Prior to Appointment: (hour)
                        </Label>
                        <Form.Control
                            as='select'
                            type='text'
                            value={hoursRequiredPriorAppointment}
                            onChange={(e) =>
                                setHoursRequiredPriorAppointment(e.target.value)
                            }
                        >
                            <option value='0'>0</option>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                        </Form.Control>
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group controlId='exampleForm.ControlSelect2'>
                        <Label>Last Appointment Time</Label>
                        <Form.Control
                            as='select'
                            type='text'
                            value={lastAppointmentTime}
                            onChange={(e) =>
                                setLastAppointmentTime(e.target.value)
                            }
                        >
                            <option value={null}></option>
                            <option value='17:00'>17:00</option>
                            <option value='17:15'>17:15</option>
                            <option value='17:30'>17:30</option>
                            <option value='17:45'>17:45</option>
                            <option value='18:00'>18:00</option>
                            <option value='18:15'>18:15</option>
                            <option value='18:30'>18:30</option>
                            <option value='18:45'>18:45</option>
                            <option value='19:00'>19:00</option>
                            <option value='19:15'>19:15</option>
                            <option value='19:30'>19:30</option>
                            <option value='19:45'>19:45</option>
                            <option value='20:00'>20:00</option>
                            <option value='20:15'>20:15</option>
                            <option value='20:30'>20:30</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>

            <Row className='mt-1'>
                <Col>
                    <FormGroup>
                        <Label>Block Time Interval:</Label>
                        <Form.Control
                            as='select'
                            type='text'
                            value={intervalBlockTime}
                            onChange={(e) =>
                                setIntervalBlockTime(e.target.value)
                            }
                        >
                            <option value='15'>15min</option>
                            <option value='30'>30min</option>
                            <option value='45'>45min</option>
                            <option value='60'>60min</option>
                        </Form.Control>
                    </FormGroup>
                </Col>

                <Col>
                    <FormGroup>
                        <Label>Display Social Medias From Star</Label>
                        <Form.Control
                            as='select'
                            type='text'
                            value={displaySocialMediaFromStar}
                            onChange={(e) =>
                                setDisplaySocialMediaFromStar(e.target.value)
                            }
                        >
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                        </Form.Control>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Allow Book Day In Future:</Label>
                        <Form.Control
                            as='select'
                            type='text'
                            value={toFutureDays}
                            onChange={(e) =>
                                setToFutureDays(e.target.value)
                            }
                        >
                            <option value='60'>30</option>
                            <option value='60'>60</option>
                            <option value='90'>90</option>
                            <option value='120'>120</option>
                            <option value='180'>180</option>
                        </Form.Control>
                    </FormGroup>
                </Col>


            </Row>

            <Button color='primary' type='submit'>
                Submit
            </Button>
        </Form>
    );
};

export default AppointmentSettingForm;
